import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import '~styles/flickity.css'
import canUseDOM from '~utils/canUseDOM'

const JumpLinkSlider = ({ className, children, onDragging }) => {

	const flkty = useRef()
	const flickityEl = useRef()

	const [init, setInit] = useState(false)
	const [dragging, setDragging] = useState(false)

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: false,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		draggable: true,
	}

	useEffect(() => {
		if (canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [init])

	useEffect(() => {
		if(flkty && init){
			flkty?.current?.on('dragStart', function(){
				setDragging(true)
			})
			flkty?.current?.on('dragEnd', function(){
				setDragging(false)
			})
		}
	}, [flkty, init])

	useEffect(() => {
		if(onDragging) {
			onDragging(dragging)
		}
	}, [dragging])

	return (
		<Wrap className={className} ref={flickityEl}>
			{children}
		</Wrap>
	)
}

const Wrap = styled.div`
	.flickity-viewport{
		overflow: visible;
	}
`

JumpLinkSlider.propTypes = {
	className: PropTypes.string,
	links: PropTypes.array,
	children: PropTypes.node,
	onDragging: PropTypes.func,
}

export default JumpLinkSlider